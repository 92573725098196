import React from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next'

const FindUs = () => {
  const { t } = useI18next()

  return (
      <div className="page-section">
          <div className="page-section-title">
              <p>{t('find-us').toUpperCase()}</p>
          </div>
          <div className="map">
              <iframe title="map" width="600" height="450" frameBorder="0" allowFullScreen src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d829.96187500951!2d19.05428836967701!3d47.56123650317911!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741d9eb152d99d7%3A0x6f48ee68b801459a!2sAitia%20International%20Zrt.!5e1!3m2!1shu!2shu!4v1736499414572!5m2!1shu!2shu" />
          </div>
      </div>
  )
}

export default FindUs
